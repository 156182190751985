import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { injectIntl } from "react-intl";
import Head from "next/head";
import ModalWindow from "../src/components/ModalWindow/ModalWindow";
import Link from "next/link";
import Router from 'next/router'

const languageItems = [
    { id: 'es', name: 'ES' },
    { id: 'en', name: 'EN' },
    { id: 'fr', name: 'FR' },
    { id: 'de', name: 'DE' },
    { id: 'pt', name: 'PT' },
    { id: 'it', name: 'IT' }
  ]


const IndexHome = (props) => {
    const [showMenu, setShowMenu] = useState(false);
    const [destination] = useState('madrid');
    const [city, setCity] = useState('madrid');
    const router = useRouter();
  
    const desktopLanguageRef = useRef();
    const languageModalRef = useRef();
  
    const getElementBounds = (ref) => ref.getBoundingClientRect();
  
    const translate = (id) => {
      return props.intl.formatMessage({ id: `landing.navbar.${id}` });
    };
  
    const onChangeLang = (lang) => {
      let url_path = router.asPath.split('/')
      url_path[1] = lang
      router.push(url_path.join('/'))
    }

    const addScrollActions = () => {
        var navbar = document.getElementById("navbar");
        var sticky = navbar.offsetTop;
        window.onscroll = function() {
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }
        };
    }

    const goTo = (url) => {
        router.push(url);
    }

    useEffect(() => {
        addScrollActions();
    }, []);

    return (
      <>
        <Head>
          <link rel="stylesheet" href="/static/css/_clear.css" />
          <link rel="stylesheet" href="/static/css/_index.css" />
        </Head>
        <nav class="nav" id="navbar">
            <div class="content-nav">
                <img class="content-logo-nav" src="/static/images/index/logo-discoolver.svg" />
                <button class="home-burger" onClick={() => {
                setShowMenu(true);
                }}>&nbsp;</button>
                <div class={`content-all-links${showMenu ? ' show' : ''}`}>
                    <div class="header-menu-show">
                        <img class="content-logo-nav" src="/static/images/index/logo-discoolver.svg" />
                        <button class="home-close-burger" onClick={() => {
                        setShowMenu(false);
                        }}>&nbsp;</button>
                    </div>
                    <div class="nav-links-space">&nbsp;</div>
                    <div class="nav-links">
                        <div class="content-nav-link clean-nav-link checked">
                            <button class="nav-link">
                                <label class="nav-link-text">Home</label>
                            </button>
                        </div>
                        <div class="content-nav-link clean-nav-link">
                            <button class="nav-link" onClick={() => goTo(`https://discoolver.com/${props.intl.locale}/destinos`)}>
                                <label class="nav-link-text">Destinos</label>
                            </button>
                        </div>
                        <div class="content-nav-link clean-nav-link">
                            <button class="nav-link" onClick={() => goTo(`https://discoolver.com/${props.intl.locale}/influencers`)}>
                                <label class="nav-link-text">Influencers</label>
                            </button>
                        </div>
                        <div class="content-nav-link clean-nav-link">
                            <button class="nav-link" onClick={() => goTo(`https://discoolver.com/${props.intl.locale}/alojamientos`)}>
                                <label class="nav-link-text">Alojamientos</label>
                            </button>
                        </div>
                    </div>
                    <div class="nav-links-space">&nbsp;</div>
                    <div class="nav-links-access">
                        <div class="content-nav-link clean-nav-link">
                            <button class="nav-link"
                                ref={desktopLanguageRef}
                                onClick={() => {
                                if (!languageModalRef.current.isVisible()) {
                                    languageModalRef.current.show({
                                    position: getElementBounds(desktopLanguageRef.current),
                                    });
                                    return;
                                }
                                languageModalRef.current.hide();
                                }}
                            >
                                <span class="language" />
                                <label class="nav-link-text">{props.intl.locale}</label>
                            </button>
                        </div>
                        <div class="content-nav-link clean-nav-link">
                            <button class="nav-link" onClick={() => goTo(`https://discoolver.com/${props.intl.locale}/${destination}/register`)}>
                            <label class="nav-link-text">Sing up</label>
                            </button>
                        </div>
                        <div class="content-nav-link clean-nav-link nav-link-login">
                            <button class="nav-link" onClick={() => goTo(`https://discoolver.com/${props.intl.locale}/${destination}/signin`)}>
                                <label class="nav-link-text">Login</label>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

        <header class="header-discoolver-hot">
            <div class="content-header">
                <div className="content-header-with-phone">
                    <div className="content-header-with-phone-text">
                        <div class="content-title">
                            <label class="content-title-text">La plataforma de recomendaciones exclusivas por influencers</label>
                        </div>

                        <div class="content-subtitle">
                            <label class="content-subtitle-text"><span>“</span>&nbsp;Explora los mejores planes recomendados por tus influencers favoritos: 🏕 alojamientos, restaurantes 🍽️ , experiencias 🏄‍♀️  y más&nbsp;<span>”</span></label>
                        </div>
                    </div>

                    <img class="header-phone" src="/static/images/index/papi-phone.png" />
                </div>
                
                
                <div class="content-cities">
                    <div class="content-select">
                        <div class="select">
                            <select value={city} onChange={(event) => setCity(event.target.value)}>
                                <option value="malaga">Malaga</option>
                                <option value="madrid">Madrid</option>
                                <option value="barcelona">Barcelona</option>
                                <option value="ronda">Ronda</option>
                                <option value="aranjuez">Aranjuez</option>
                                <option value="punta_cana">Punta Cana</option>
                                <option value="santo_domingo">Santo Domingo</option>
                            </select>
                            <img src="/static/images/index/arrow-drop-down.svg" />
                        </div>
                        
                        <button class="btn" onClick={() => {
                            Router.push(
                            `/${props.intl.locale}/${city}`
                            );
                        }}>
                            <label class="btn-text">Buscar</label>
                        </button>
                    </div>
                </div>

            </div>
        </header>

        <section class="content-body-title content-body-title-row">
            <h1 class="h1"><span>Generando orden en el caos...</span></h1>
            <h2 class="h2"><span>Descubrimos y seleccionamos los lugares más cool de Instagram y otras redes, y los organizamos especialmente para ti.</span></h2>
        </section>

        <section class="content-category-cards not-top-padding">
        <div class="category-card">
                <div class="category-card-image category-card-image-nightlife">&nbsp;</div>
                <div class="category-card-info">
                    <label class="category-card-info-text">#<span>NIGHTLIFE</span></label>
                </div>
            </div>
            <div class="category-card">
                <div class="category-card-image category-card-image-gastronomia">&nbsp;</div>
                <div class="category-card-info">
                    <label class="category-card-info-text">#<span>RESTAURANTES</span></label>
                </div>
            </div>
            <div class="category-card">
                <div class="category-card-image category-card-image-lifestyle">&nbsp;</div>
                <div class="category-card-info">
                    <label class="category-card-info-text">#<span>OCIO Y EVENTOS</span></label>
                </div>
            </div>
            <div class="category-card">
                <div class="category-card-image category-card-image-art">&nbsp;</div>
                <div class="category-card-info">
                    <label class="category-card-info-text">#<span>ARTE Y CULTURA</span></label>
                </div>
            </div>
            <div class="category-card">
                <div class="category-card-image category-card-image-moda">&nbsp;</div>
                <div class="category-card-info">
                    <label class="category-card-info-text">#<span>COMPRAS Y MODA</span></label>
                </div>
            </div>
            <div class="category-card">
                <div class="category-card-image category-card-image-alojamientos">&nbsp;</div>
                <div class="category-card-info">
                    <label class="category-card-info-text">#<span>ALOJAMIENTOS</span></label>
                </div>
            </div>
            <div class="category-card">
                <div class="category-card-image category-card-image-experiencias-unicas">&nbsp;</div>
                <div class="category-card-info">
                    <label class="category-card-info-text">#<span>EXPERIENCIAS ÚNICAS</span></label>
                </div>
            </div>
            <div class="category-card">
                <div class="category-card-image category-card-image-familias-peques">&nbsp;</div>
                <div class="category-card-info">
                    <label class="category-card-info-text">#<span>FAMILIAS Y PEQUES</span></label>
                </div>
            </div>
            <div class="category-card">
                <div class="category-card-image category-card-image-naturaleza">&nbsp;</div>
                <div class="category-card-info">
                    <label class="category-card-info-text">#<span>NATURALEZA</span></label>
                </div>
            </div>
            <div class="category-card">
                <div class="category-card-image category-card-image-salud">&nbsp;</div>
                <div class="category-card-info">
                    <label class="category-card-info-text">#<span>SALUD</span></label>
                </div>
            </div>
        </section>

        <br />

        <section class="content-body-title">
            <h1 class="h1"><span>¡Todo al alcance de tu mano!</span></h1>
            <h2 class="h2"><span>Descarga la app y descubre los secretos locales como un verdadero insider.</span></h2>
            <div class="content-apps-store no-padding">
                <a href="https://apps.apple.com/es/app/discoolver/id1529749267" target="_blank"><img src="/static/images/index/play-store-badge.svg" /></a>
                <a href="#" target="_blank"><img src="/static/images/index/google-play-badge.svg" /></a>
            </div>
        </section>

        <br/>

        <section class="list-content-blue-card">
            <section class="content-blue-card">
                <div class="blue-card">
                    <div class="blue-card-title">
                        Tecnología basada en IA
                    </div>
                    <div class="list-cards">
                        <div class="content-card">
                            <div class="card">
                                <div class="card-image card-image-recomendaciones">&nbsp;</div>
                                <h5 class="card-title"><span>Detector de tendencias</span></h5>
                                <label class="card-subtitle">Gracias a la IA detectamos las recomendaciones más interesantes de cada destino para ti.</label>
                                <label class="card-subtitle">No vuelvas a perderte nada importante.</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
            <section class="content-blue-card">
                <div class="blue-card">
                    <div class="blue-card-title">
                        Contenidos de influencers
                    </div>
                    <div class="list-cards">
                        <div class="content-card">
                            <div class="card">
                                <div class="card-image card-image-influencers">&nbsp;</div>
                                <h5 class="card-title"><span>Las recomendaciones + cool</span></h5>
                                <label class="card-subtitle">Descubre la ciudad a través de los ojos de tus influencers favoritos.</label>
                                <label class="card-subtitle">Todas sus recomendaciones como nunca habías imaginado adaptando a tu perfil.</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        
        <br />

        <section class="content-body-title">
            <h4 class="h4"><span>Prueba nuestras herramientas</span></h4>
            <div class="list-cards">
                <div class="content-card">
                    <div class="card">
                        <div class="card-image card-image-geo">&nbsp;</div>
                        <h5 class="card-title"><span>Plan My Trip</span></h5>
                        <label class="card-subtitle padding">Planifica tu proximo viaje en cuestion de segundos con el plan perfecto para ti y los tuyos.</label>
                    </div>
                </div>

                <div class="content-card">
                    <div class="card">
                        <div class="card-image card-image-calendar-white">&nbsp;</div>
                        <h5 class="card-title"><span>Smart Calendar</span></h5>
                        <label class="card-subtitle padding">No te pierdas nada en tu ciudad o alla donde vayas.</label>
                    </div>
                </div>

                <div class="content-card">
                    <div class="card">
                        <div class="card-image card-image-local-buddy">&nbsp;</div>
                        <h5 class="card-title"><span>Local Buddy</span></h5>
                        <label class="card-subtitle padding">Un asistente de voz que te atiende como un amigo experto en el destino.</label>
                    </div>
                </div>
            </div>
        </section>

        <br />
        
        <section class="list-content-blue-card blue-card-individual">
            <section class="content-blue-card">
                <div class="blue-card">
                    <div class="blue-card-title">
                        Una re-evolución de recomendaciones personalizadas
                    </div>
                    <div class="list-cards">
                        <div class="content-card">
                            <div class="card">
                                <h5 class="card-title"><span>La mejor solución para disfrutar de recomendaciones auténticas y personalizadas para cada rincón del mundo.</span></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <section class="content-body-title">
            <div class="list-humans">
                <div class="content-human">
                    <div class="human">
                        <div class="human-image human-image-asking-locals">&nbsp;</div>
                        <div>
                            <h5 class="human-title"><span>Preguntando a locales</span></h5>
                            <span class="humam-message">Obtuviste consejos auténticos de quienes vivían en el lugar.</span>
                        </div>
                        
                    </div>
                </div>
                <div class="content-human">
                    <div class="human">
                        <div class="human-image human-image-travel-guides">&nbsp;</div>
                        <div>
                            <h5 class="human-title"><span>Guías de viaje</span></h5>
                            <span class="humam-message">Consultaste información esencial para explorar el destino.</span>
                        </div>
                    </div>
                </div>
                <div class="content-human">
                    <div class="human">
                        <div class="human-image human-image-online-directories">&nbsp;</div>
                        <div>
                            <h5 class="human-title"><span>Directorios en línea</span></h5>
                            <span class="humam-message">Encontraste eventos y lugares populares de forma rápida.</span>
                        </div>
                    </div>
                </div>
                <div class="content-human">
                    <div class="human">
                        <div class="human-image human-image-users-opinions">&nbsp;</div>
                        <div>
                            <h5 class="human-title"><span>Opiniones de usuarios</span></h5>
                            <span class="humam-message">Leíste reseñas útiles para decidir qué lugares visitar.</span>
                        </div>
                    </div>
                </div>
                <div class="content-human">
                    <div class="human checked">
                        <div class="human-image human-image-content-cretors">&nbsp;</div>
                        <div>
                            <h5 class="human-title"><div class="human-icon human-icon-content-cretors">&nbsp;</div></h5>
                            <span class="humam-message">Ahora descubres recomendaciones de influencers locales.</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <br />

        <section class="list-content-blue-card blue-card-individual">
            <section class="content-blue-card">
                <div class="blue-card">
                    <div class="blue-card-title">
                        Local Buddy
                    </div>
                    <div class="list-cards">
                        <div class="content-card">
                            <div class="card">
                            <div class="card-image card-image-flotante card-image-local-buddy">&nbsp;</div>
                                <h5 class="card-title"><span>El chatbot local que se sabe todas</span></h5>
                                <label class="card-subtitle">Con este software, puedes hacer preguntas específicas sobre actividades, lugares de interés, eventos locales y mucho más. A través de un diálogo interactivo, recibirás artículos y sugerencias que se ajustan a tus necesidades.</label>
                                <label class="card-subtitle">Busca un restaurante exclusivo, una actividad al aire libre o una exposición artística, nuestro asistente de inteligencia artificial está aquí para guiarte, asegurando que aproveches al máximo tu experiencia en la ciudad.</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <br />
        
        <section class="content-guide">
            <div class="margin-126">&nbsp;</div>
            <div class="content-guide-info">
                <section class="content-body-title not-padding">
                    <h4 class="h4"><span>“Tu guía exclusiva con las mejores recomendaciones: ¡Descárgala y a probar cositas!”</span></h4>
                </section>
                <div class="content-card">
                    <div class="card">
                        <label class="card-subtitle">Accede a las mejores recomendaciones del año con nuestra guía gratuita y vive experiencias inolvidables.</label>
                    </div>
                </div>
                <div class="content-select">
                    <div class="select">
                        <select>
                            <option value="madrid">Madrid</option>
                            <option value="mundo">Mundial</option>
                        </select>
                        <img src="/static/images/index/arrow-drop-down.svg" />
                    </div>
                    
                    <button class="btn">
                        <label class="btn-text"><img src="/static/images/index/btn-download.svg"/></label>
                    </button>
                </div>
            </div>
            <div class="content-guide-image">
                <div class="guide-image">
                    &nbsp;
                </div>
                <div class="content-select">
                    <div class="select">
                        <select>
                            <option value="madrid">Madrid</option>
                            <option value="mundo">Mundial</option>
                        </select>
                        <img src="/static/images/index/arrow-drop-down.svg" />
                    </div>
                    
                    <button class="btn">
                        <label class="btn-text"><img src="/static/images/index/btn-download.svg"/></label>
                    </button>
                </div>
            </div>
            <div class="">&nbsp;</div>
        </section>

        <br/>

        <section class="content-body-title">
            <h1 class="h1"><span>¿Qué opinan nuestros usuarios?</span></h1>
        </section>
        
        <section class="list-content-blue-card">
            <section class="content-blue-card">
                <div class="blue-card card-influencer">
                    <div class="card-influencer-text">
                        <span class="card-influencer-text-quote">”</span>
                        <span class="card-influencer-text-message">Me encanta crear mis propios listados de lugares a visitar,  gracias a discoolver estoy conociendo Madrid más que nunca.</span>
                    </div>
                    <div class="card-influencer-person">
                        <img class="card-influencer-person-photo" src="/static/images/index/influencers-laura.jpeg"/>
                        <div>
                            <span class="card-influencer-person-name">Laura Muñoz</span>
                            <span class="card-influencer-person-work">Influencer</span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content-blue-card">
                <div class="blue-card card-influencer">
                    <div class="card-influencer-text">
                        <span class="card-influencer-text-quote">”</span>
                        <span class="card-influencer-text-message">Fantastica herramienta para descubrir los mejores sitios de la ciudad. La he usado para organizar todo mi trip por España ¿Cuando lanzan en Colombia?</span>
                    </div>
                    <div class="card-influencer-person">
                        <img class="card-influencer-person-photo" src="/static/images/index/influencers-jorge.jpeg"/>
                        <div>
                            <span class="card-influencer-person-name">Jorge Delgado</span>
                            <span class="card-influencer-person-work">Digital Nomad</span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content-blue-card">
                <div class="blue-card card-influencer">
                    <div class="card-influencer-text">
                        <span class="card-influencer-text-quote">”</span>
                        <span class="card-influencer-text-message">Desde que vine a España siempre he querido saber donde ir para conocer a gente local y disfrutar con ellos. Gracias a vuestra herramienta ahora soy quien propone planes a mis colegas locales.</span>
                    </div>
                    <div class="card-influencer-person">
                        <img class="card-influencer-person-photo" src="/static/images/index/influencers-jessica.jpeg" />
                        <div>
                            <span class="card-influencer-person-name">Jessica Lauffer</span>
                            <span class="card-influencer-person-work">Dancer</span>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <br />

        <div class="section-row section-patner-city">

            <div class="section-column">
                <section class="content-blue-card">
                    <div class="blue-card">
                        <div class="blue-card-title blue-card-title-left">
                            Ya confían en nosotros...
                        </div>
                    </div>
                </section>
    
                <section class="content-parnets">
                    <div class="list-parnets">
                        <center><div class="parnet-image parnet-image-segitur">&nbsp;</div></center>
                        <center><div class="parnet-image parnet-image-destino-turistico">&nbsp;</div></center>
                        <center><div class="parnet-image parnet-image-vocces">&nbsp;</div></center>
                        <center><div class="parnet-image parnet-image-tetuan-valley">&nbsp;</div></center>
                        <center><div class="parnet-image parnet-image-tourism-hub">&nbsp;</div></center>
                    </div>
                </section>
            </div>

            <div class="section-column">
                <section class="content-body-title content-city">
                    <h4 class="h4 dark"><span>Próximamente en:</span></h4>
                    <div class="content-map-city">
                        <div class="content-map-city-img">&nbsp;</div>
                    </div>
                </section>
            </div>
        </div>

        <br />

        <section class="content-blue-card">
            <div class="blue-card">
                <div class="blue-card-title blue-card-title-left">
                    Descubre nuestras soluciones para:
                </div>
                <div class="content-solutions-cards">
                    <div class="content-solutions-card first-card">
                        <div class="content-solutions-card-image">
                            &nbsp;
                        </div>
                        <div class="content-solutions-card-text">
                            <div class="content-btn">
                                <button class="btn">
                                    <label class="btn-text">Destinos</label>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="content-solutions-card middle-card">
                        <div class="content-solutions-card-image">
                            &nbsp;
                        </div>
                        <div class="content-solutions-card-text">
                            <div class="content-btn">
                                <button class="btn">
                                    <label class="btn-text">Alojamientos</label>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="content-solutions-card last-card">
                        <div class="content-solutions-card-image">
                            &nbsp;
                        </div>
                        <div class="content-solutions-card-text">
                            <div class="content-btn">
                                <button class="btn">
                                    <label class="btn-text">Influencers</label>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="content-body-title content-want-us-in-your-city">
            <h5 class="h5">
                <span>¿Quieres que Discoolver este en tu ciudad?</span>
            </h5>
            <div class="content-want-us-in-your-city-buttons">
                <div class="content-btn">
                    <button class="btn">
                        <label class="btn-text">Prensa</label>
                    </button>
                </div>
                <div class="content-btn">
                    <button class="btn">
                        <label class="btn-text">Inversores</label>
                    </button>
                </div>
            </div>
        </section>

        <footer class="footer-discoolver-hot">
            <img class="content-logo" src="/static/images/index/logo-discoolver.svg" />
            <div class="content-footer">
                <div class="content-title">
                    <label class="content-title-text">Contacta con nosotros para resolver cualquier duda</label>
                </div>

                <div class="content-btn">
                    <button class="btn">
                        <label class="btn-text">¿Te ayudamos?</label>
                    </button>
                </div>
                <div class="content-links">
                    <div class="content-links-column">
                        <div class="content-info">
                            <h1 class="h1">Contacto</h1>
                            <label class="content-info-text">info@discoolver.com<br/>(+34) 681 291 571<br/>C/ María de Molina 39, 28006<br/>Madrid</label>
                        </div>

                        <div class="content-social-icons">
                            <a href="https://www.facebook.com/discoolvermad/" target="_blank"><img class="icons" src="/static/images/index/facebook.svg" /></a>
                            <a href="https://twitter.com/discoolver_mad" target="_blank"><img class="icons" src="/static/images/index/X.png" /></a>
                            <a href="https://www.instagram.com/discoolver_mad/" target="_blank"><img class="icons" src="/static/images/index/instagram.png" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <ModalWindow
          title={''}
          ref={languageModalRef}
        >
          {languageItems.map((d) => {
            return (
              <button
                onClick={() => {
                  onChangeLang(d.id)
                  languageModalRef.current.hide();
                }}
                key={d.id}
                class={`languageModalItem${props.intl.locale === d.id ? ' selected' : ''}`}
              >
                <span>
                  {props.intl.formatMessage({
                    id: d.name,
                  })}
                </span>
              </button>
            );
          })}
        </ModalWindow>
      </>
    );
}

export default injectIntl(IndexHome);